<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container filters" style="position: relative;padding-right: 140px;">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="优惠券名称"></el-input>
			</div>
			<!--优惠券类型  -->
			<div class="filter-item">
				<label class="label">优惠券类型: </label>
				<el-select v-model="type" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in typeOptions" :key="item.value" :label="item.lable" :value="item.value"></el-option>
				</el-select>
			</div>
			<!-- 优惠券状态 -->
			<div class="filter-item">
				<label class="label">优惠券状态: </label>
				<el-select v-model="status" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option
						v-for="item in statusOptions"
						:key="item.value"
						:label="item.lable"
						:value="item.value">
					</el-option>
				</el-select>
			</div>
			<!--员工小程序展示  v-if="isShowWxAppSwitch && mallInfo.IsUseQyWeixin" -->
			<div class="filter-item">
				<label class="label">员工小程序展示: </label>
				<el-select v-model="isShowWxApp" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in wxAppOptions" :key="item.value" :label="item.lable" :value="item.value"></el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addVoucher'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleAddTable" >添加</el-button>
				</button-permissions>
			</div>
			
			<div style="position: absolute;right: 20px;top: 0px;">
				<experience-code></experience-code>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table	:data="tableData"	style="width: 100%" v-loading="loading">
				<el-table-column	prop="CouponName"	label="优惠券"></el-table-column>
				<el-table-column	prop="UseTypeValue"	label="类型"></el-table-column>
				<el-table-column  prop="IsOpenValue" label="状态"></el-table-column>
				<el-table-column	prop="CouponContent"	label="优惠内容"></el-table-column>
				<el-table-column	prop=""	label="已领取/剩余">
					<template slot-scope="scope">
						<span>{{scope.row.SendCount}}/{{scope.row.LeaveCount}}</span>
					</template>
				</el-table-column>
				<el-table-column	prop="UseCount"	label="已使用"></el-table-column>	
				<!--  v-if="isShowWxAppSwitch && mallInfo.IsUseQyWeixin" -->
				<el-table-column	label="员工小程序展示" >
					<template slot-scope="scope">
						<el-switch
							v-model="scope.row.IsShowEmployeeWxApp"
							@change="changeWxAppSwicth(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column	prop="AddTime"	label="创建时间"></el-table-column>
				<el-table-column	label="操作" width='250px'>
					<template slot-scope="scope" >

						<div v-if="!scope.row.IsOpen">
							<div class="table-button" @click="handleTableEdit(scope.row,0)">查看</div>

							<button-permissions :datas="'data'" >
								<div class="table-button" @click="handleTableDataOpen(scope.row.Id,scope.row.CouponName)">数据</div>
							</button-permissions>

							<button-permissions :datas="'copyVoucher'" >
								<div class="table-button" @click="handleTableCopy(scope.row)">复制</div>
							</button-permissions>

							<button-permissions :datas="'deleteVoucher'" >
								<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.Id)">删除</div>
							</button-permissions>

						</div>

						<div v-else>

							<button-permissions :datas="'editVoucher'" >
								<div class="table-button" @click="handleTableEdit(scope.row,1)">编辑</div>
							</button-permissions>

							<button-permissions :datas="'data'" >
								<div class="table-button" @click="handleTableDataOpen(scope.row.Id,scope.row.CouponName)">数据</div>
							</button-permissions>

							<button-permissions :datas="'voucherTg'">
								<div class="table-button" @click="handleTablePromote(scope.row.Id)">推广</div>
							</button-permissions>

							<el-dropdown  >
								<el-button  type="text">
									更多
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item >
										<button-permissions :datas="'cancelVoucher'" >
											<div class="table-button" @click="handleTableFail(scope.row.Id)">使失效</div>
										</button-permissions>
									</el-dropdown-item>
									
									<el-dropdown-item>
										<button-permissions :datas="'copyVoucher'" >
											<div class="table-button" @click="handleTableCopy(scope.row)">复制</div>
										</button-permissions>
									</el-dropdown-item>

									<el-dropdown-item>
										<button-permissions :datas="'codeVoucher'">
											<div class="table-button" @click="showCouponCode(scope.row.Id)">生成券码</div>
										</button-permissions>
									</el-dropdown-item>

								</el-dropdown-menu>
							</el-dropdown>

						</div>

					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>
		<!-- add-dialog -->
			<!-- :before-close="handleClose" -->
		<el-dialog
			title="添加优惠券"
			:visible.sync="editDialog"
			width="40%"
			class="dialog">
			<div  class="content">
				<div class="left">我要添加</div>
				<div class="right">
						<el-radio v-model="editRadio" :label="0">满减券</el-radio>
						<div class="text">可为用户提供减免订单金额服务，你可以设置“满X元减Y元”</div>
  					<el-radio v-model="editRadio" :label="1">打折券</el-radio>
						<div class="text">可为用户提供订单打折服务，你可以设置“满X元打Y折”</div>
						<el-radio v-model="editRadio" :label="2">随机金额券</el-radio>
						<div class="text">用户领取时，在随机金额区间内，随机生成减免金额，你可以设置“满X元减Y1~Y2元”</div>
				</div>
			</div>
			<div  class="dialog-footer">
				<el-button class="button" @click="addCouponSure" type="primary">确定</el-button>
			</div>
		</el-dialog>

		<!-- data-dialog -->
		<el-dialog
			title="效果数据"
			:visible.sync="showDataDialog"
			width="50%"
			class="dialog data-dialog">
			<el-card class="box-card" v-loading="coupondetailLading">
				<div slot="header" class="clearfix">
					<span>优惠券名称：{{currentCouponName}}</span>
				</div>
				<el-row :gutter="20">
					<el-col :span="6">
						<div class="item">
							<div>
								用券总支付额 
								<el-tooltip class="item" effect="dark" content="使用了该优惠券的订单支付金额（含运费）" placement="top">
									<i class="el-icon-info"></i>
								</el-tooltip>
							</div>
							<span class="value">{{couponStatistical.PayMoney }}</span>
						</div>
						<div class="item">
							<div>用券老客户数</div>
							<span class="value">{{couponStatistical.OldCustomerCount}}</span>
						</div>
					</el-col>

					<el-col :span="6">
						<div class="item">
							<div>
								优惠总金额 
								<el-tooltip class="item" effect="dark" content="使用了该优惠券后，优惠的总金额" placement="top">
									<i class="el-icon-info"></i>
								</el-tooltip>
							</div>
							<span class="value">{{couponStatistical.PerferMoney}}</span>
						</div>
						<div class="item">
							<div>用券新客户数</div>
							<span class="value">{{couponStatistical.NewCustomerCount}}</span>
						</div>
					</el-col>

					<el-col :span="6">
						<div class="item">
							<div>
								费效比 
								<el-tooltip class="item" effect="dark" content="=优惠总金额/订单支付金额*100%" placement="top">
									<i class="el-icon-info"></i>
								</el-tooltip>
							</div>
							<span class="value">{{couponStatistical.FeeEffectRate }}</span>
						</div>
						<div class="item">
							<div>用券商品数</div>
							<span class="value">{{couponStatistical.ProductCount }}</span>
						</div>
					</el-col>
					
					<el-col :span="6">
						
						<div class="item">
							<div>
								用券客单价 
								<el-tooltip class="item" effect="dark" content="=用券总支付额/用户客户数" placement="top">
									<i class="el-icon-info"></i>
								</el-tooltip>
							</div>
							<span class="value">{{couponStatistical.CustomerUnitPrice}}</span>
						</div>

						<div class="item">
							<div>
								用券笔单价 
								<el-tooltip class="item" effect="dark" content="=用券总支付额/用券切支付成功的订单数" placement="top">
									<i class="el-icon-info"></i>
								</el-tooltip>
							</div>
							<span class="value">{{couponStatistical.UseCouponUnitMoney}}</span>
						</div>
					</el-col>
				</el-row>
				
				<div style="margin:20px 0">
					<span>使用优惠券购买的商品</span>
				</div>

				<el-table	:data="couponStatistical.Results"	style="width: 100%">
					<el-table-column	prop="ProductName"	label="商品"></el-table-column>
					<el-table-column	prop="PayCount"	label="支付件数"></el-table-column>
					<el-table-column	prop="CustomerCount"	label="支付人数"></el-table-column>
				</el-table>
				<el-pagination class="page-content" v-if="dialogPage.total" style="margin-top:20px;float:right;" @size-change="handleDialogSizeChange" @current-change="handleDialogCurrentChange"
				:current-page="dialogPage.current" :page-sizes="[5,10, 20, 30, 40, 50]" :page-size="dialogPage.size" layout="total, sizes, prev, pager, next, jumper"
				:total="dialogPage.total">
				</el-pagination>
			</el-card>
		</el-dialog>

		<!-- 推广的弹框 -->
		<batch-cods  :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData"  @cancel="weiCodeCancel"></batch-cods>
		<!-- 生成券码 -->
		<CouponCode :codeVisible="codeVisible" :couponId='codeCouponId' @closedCode="closedCode" ></CouponCode>
	</div>
</template>

<script>
	import buttonPermissions from '@/components/buttonPermissions';
	import batchCods from "@/components/bathCodes"
	import apiList	from '@/api/other'
	import CouponCode from "./CouponCode";
	
	import experienceCode from '@/components/experienceCode';
	
	import {
		mapGetters
	} from 'vuex'
	export default {
		components: {
			buttonPermissions,
			batchCods,
			CouponCode,
			experienceCode
		},
		computed:{
			...mapGetters([
				"mallInfo"
			]),
		},
		data(){
			return{
				weiCodeData:{},
				isMoreShow:false,
				weiCodeShow:false,
				bathSortDatas: [],
				searchKey:'',
				type:null,
				loading: false,
				typeOptions:[
					{
						lable:'满减券',value:0
					},
					{
						lable:'打折券',value:1
					},
					{
						lable:'随机金额券',value:2,
					}
				],
				isShowWxApp: null,
				wxAppOptions:[
					{
						lable:'是',value:true
					},
					{
						lable:'否',value:false
					}
				],
				status:null,
				statusOptions:[
					{
						lable:'进行中',value:true
					},
					{
						lable:'已失效',value:false
					}
				],
				tableData:[
					{
						name:'sss'
					}
				],
				page:{
					total:0,
					current:1,
					size:20
				},
				coupondetailLading:false,
				couponStatistical:{
					FeeEffectRate: '%',
					NewCustomerCount:'0',
					OldCustomerCount:'0',
					OrderCount:'0',
					PayMoney: '0',
					PerferMoney:'0',
					ProductCount:'0',
					Results:[],
					Total:'',
					UseCouponUnitMoney:'0',
				},
				currentCouponName:'',
				// edit
				editDialog:false,
				editRadio:0,
				showDataDialog:false,
				currentDataId:'',
				dialogPage:{
					total:0,
					current:1,
					size:5
				},
				dialogTableData:[
					{name:'1354'}
				],
				currentEditId: null,

				codeVisible: false,
				codeCouponId: null,

				isShowWxAppSwitch: false,
			}
		},
		created(){
			//typeOptions
			console.log(this.$route.params.type)
			if(this.typeOptions.some((v)=>{return v.value == this.$route.params.type})){
				this.editDialog = true
				this.editRadio = Number(this.$route.params.type)
			}
			this.getList()
			this.getShopmallName()
		},
    methods: {
			getShopmallName() {
				let data = {}
				this.$store.dispatch('GetPcShopInfo', data).then((res) => {
					this.isShowWxAppSwitch = res.Result.IsUseQyWeixin
				}).catch((e) => {})
			},
			async changeWxAppSwicth(val) {
				try {
					let data = {
						Id: val.Id,
						IsShowEmployeeWxApp: val.IsShowEmployeeWxApp,
					}
					await apiList.activitycouponisshowemployeewxapp(data);
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功'
					});
					this.getList()
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			
			// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						KeyWords:this.searchKey,
						isShowEmployeeWxApp: this.isShowWxApp,
						UseType: this.type,
						IsOpen: this.status,
						Skip: (this.page.current - 1) * this.page.size, 
						Take: this.page.size, 
					}
					let result = await apiList.activitycouponCouponlist(data);
					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results || [];
					this.tableData.forEach(t=>{
						t.UseOrderPrice = t.UseOrderPrice.toFixed(2)
						t.UseCouponUnitMoney = t.UseCouponUnitMoney.toFixed(2)
						t.CustomerUnitPrice = t.CustomerUnitPrice.toFixed(2)
					})
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
						this.loading = false;
				}
			},
			//添加优惠券
			handleAddTable(){
				this.editDialog=true
			},
			handleFilter(){
				this.page.size = 20
				this.page.current = 1
				this.getList()
			},
			//新增优惠券
			addCouponSure(){
				this.$router.push({ path: '/market/coupon/editDiscountTicketDetail',query:{type:this.editRadio, fromRoute: this.$route.name}})
			},
			//编辑，查看
			handleTableEdit(val,status){
				// status:1:编辑 0：查看
				this.$router.push({ path: '/market/coupon/editDiscountTicketDetail',query:{id:val.Id,type:val.UseType,status:status}})
			},
			//复制
			handleTableCopy(val){
				this.$router.push({ path: '/market/coupon/editDiscountTicketDetail',query:{copyId:val.Id,type:val.UseType, fromRoute: this.$route.name}})
			},
			showCouponCode(id) {
				this.codeVisible = true
				this.codeCouponId = id
			},
			//使失效
			handleTableFail(id){
				this.$confirm('优惠券失效后，不会影响已经发放的优惠券，你将不可再对这张优惠券进行编辑，是否确认使失效？', '提示', {
					confirmButtonText: '确认失效',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					let result = await apiList.activitycouponActivitycouponsetisopen({
						Id: id
					})
					this.$message({showClose: true,
						type: 'success',
						message: '失效成功!'
					});
					this.getList()

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消失效'
					});
				}).finally(() => {
					// this.getList()
				})
			},
				// table数据统计
			handleTableDataOpen(id, name){
				this.currentCouponName = name
				this.currentDataId = id
				this.handleTableData()
			},
			async handleTableData(){
				this.showDataDialog=true
				this.coupondetailLading = true;
				try {
					let data = {
						Id:this.currentDataId,
						Skip: (this.dialogPage.current - 1) * this.dialogPage.size, 
						Take: this.dialogPage.size, 
					}
					let result = await apiList.activitycouponActivitycouponstatistical(data);
					this.couponStatistical=result.Result|| []
					this.dialogPage.total=result.Result.Total
				} catch (error) {
					console.log(error);
				} finally {
					this.coupondetailLading = false;
				}
			},
				// table删除
			handleTableDelete(id){
				this.$confirm('是否确认删除这张优惠券?删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async() => {
					await apiList.activitycouponActivitycoupondelete({
						Id: id
					})
					this.$message({showClose: true,
						type: 'success',
						message: '删除成功!'
					});
					this.getList()

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					// this.getList();
				})
			},
			getRamNumber(){
				var result='';
				for(var i=0;i<6;i++){
				
					result+=Math.floor(Math.random()*16).toString(16);
				}
				
				return result;
			},
			// 推广-优惠券
			handleTablePromote(id){
				this.weiCodeShow = true;
				let uid =this.getRamNumber()
				this.weiCodeData = {
					Page:'pages/r/r',
					Scene:`id=${id}&uid=${uid}`
				}
			},
			weiCodeCancel() {
				this.weiCodeShow = false;
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
			// 切换显示条数
			handleDialogSizeChange(val) {
				this.dialogPage.size = val;
				this.handleTableData();
			},
			// 翻页
			handleDialogCurrentChange(val) {
				this.dialogPage.current = val;
				this.handleTableData();
			},
			closedCode() {
				this.codeVisible = false
			}
    }
	}
</script>

<style  lang="less" scoped>
.app-container{
	.filters {
		::v-deep .el-input {
			width: 220px;
		}
	}
	background-color: #fff;
}
	.table-button{
		color: #409EFF;
		display: inline-block;
		margin-right: 15px;
		cursor: pointer;
	}
	.dialog{
		width: 100%;
		::v-deep .el-dialog__header {
				border-bottom: 1px solid #ddd;
		}
		.content{
			padding: 0 30px;
			display: flex;
			flex-direction: row;
			.right{
				margin-left: 20px;
			}
			.text{
				margin-left: 20px;
				font-size: 14px;
				color: #999;
				line-height: 40px;
			}
		}
		.button{
			margin: 0 auto;
			width: 200px;
		}
	}
	.data-dialog{
		.value{
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}
		.item{
			color: #666;
			margin-top: 10px;
		}
	}
	.dialog-footer{
		margin-top:30px;
		text-align: center;
	}
	.el-pagination{
		::v-deep .el-input{
			width:100px;
		}
	}
</style>
