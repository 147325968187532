<template>
	<div>
		<el-dialog title="生成券码" :visible.sync="codeShow" width="80%" class="dialog" @closed='closed'>
			<div class="top">
				<div class="config">
					<label>关闭/开启券码：</label>
					<div>
						<el-switch style="margin-bottom:5px;" v-model="switchValue" @change="handelEditCode"></el-switch>
						<div class="remark">开启券码功能，客户扫优惠券推广码领券需输入优惠券券码领取</div>
					</div>
				</div>
				<div class="config">
					<label>领券背景图：</label>
					<el-upload accept="image/png,image/jpeg,image/jpg,image/gif" class="avatar-uploader" :action="imgApi"
					 :show-file-list="false" :on-success="handleAvatarSuccess" list-type="picture-card" style="display: inline-block;marin-left:10px;">
						<img v-if="backUrl" :src="imgUrl + backUrl" class="upload-avatar" />
						<i v-else class="el-icon-plus"></i>
					</el-upload>
					<span class="remark">建议上传图片尺寸，宽750px ,高大于1480，大小小于2M</span>
				</div>
			</div>
			<div class="filter-container">
				<!-- 关键字搜索 -->
				<div class="filter-item">
					<label class="label">关键字: </label>
					<el-input v-model="searchKey" placeholder="券码、客户名称" style="width:200px"></el-input>
				</div>
				<div class="filter-item">
					<label class="label">状态: </label>
					<el-select v-model="status" placeholder="请选择" style="width:150px">
						<el-option v-for="item in statusOptions" :key="item.value" :label="item.lable" :value="item.value"> </el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<el-select v-model="timeType" placeholder="请选择" style="width:110px;margin-right:5px;">
						<el-option v-for="item in timeOptions" :key="item.value" :label="item.lable" :value="item.value"> </el-option>
					</el-select>
					<el-date-picker format="yyyy-MM-dd HH:mm:ss" style="width:200px;" value-format="yyyy-MM-dd HH:mm:ss" v-model="startTime"
					 type="datetime" placeholder="开始时间"></el-date-picker>
					<span style="margin:0 10px;">~</span>
					<el-date-picker format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" style="width:200px;" value-format="yyyy-MM-dd HH:mm:ss"
					 v-model="endTime" type="datetime" placeholder="结束时间"></el-date-picker>
				</div>
				<div class="filter-item">
					<el-button type="primary" class="btn" @click="handleFilter">查询</el-button>
					<el-popover placement="bottom" width="400" trigger="click" v-model="popoverVisible">
						<template>
							<el-input placeholder="请输入生成数量" v-model="count" @input='count=validateIntCount(count)' style="width:200px;margin-right:10px"></el-input>
							<el-button type="primary" class="btn" v-loading='saveLoading' @click="savePopover">确定</el-button>
							<el-button class="btn" @click="canclePopover">取消</el-button>
						</template>
						<el-button type="primary" slot="reference" class="btn">生成券码</el-button>
					</el-popover>
					<el-button class="btn" @click="handleExport">导出</el-button>
				</div>
			</div>
			<!-- table -->
			<div class="table-container">
				<el-table :data="tableData" style="width: 100%" v-loading='loading' max-height="310px">
					<el-table-column prop="CouponCode" label="优惠券券码"></el-table-column>
					<el-table-column prop="StateValue" label="状态" width="120px"></el-table-column>
					<el-table-column label="客户">
						<template slot-scope="scope">
							{{scope.row.NameShow}}
							<span v-if='scope.row.Phone'>（{{scope.row.Phone}}）</span>
						</template>
					</el-table-column>
					<el-table-column prop="AddTime" label="创建时间"></el-table-column>
					<el-table-column prop="SendTimeValue" label="发放时间"></el-table-column>
					<el-table-column prop="UseTimeValue" label="使用时间"></el-table-column>
					<el-table-column prop="ActivityTime" label="关联订单">
						<template slot-scope="scope">
							<el-button type="text" @click="openNewWindow(scope.row.OrderNo)">{{scope.row.OrderNo}}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination v-if="page.total" style="margin-top:10px;float:right;" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5, 10]" :page-size="page.size"
				 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
				</el-pagination>
			</div>

		</el-dialog>
	</div>
</template>

<script>
	import {
		activitycouponcodeinfo,
		activitycouponcodeedit,
		activitycouponcodeindex,
		activitycouponcodegenerate
	} from '@/api/goods';
	import config from '@/config/index';
	import {
		validateInt
	} from '@/utils/validate';
	export default {
		props: {
			codeVisible: {
				type: Boolean,
				default: false
			},
			couponId: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				exportUrl: config.EXPORT_URL,

				codeShow: false,
				switchValue: false,
				backUrl: '',

				popoverVisible: false,
				count: null,
				saveLoading: false,

				loading: false,
				searchKey: '',
				status: null,
				statusOptions: [{
						lable: '全部',
						value: null
					},
					{
						lable: '未发放',
						value: 0
					},
					{
						lable: '可使用',
						value: 1
					},
					{
						lable: '已使用',
						value: 2
					},
					{
						lable: '已失效',
						value: 3
					}
				],
				timeType: 0,
				timeOptions: [{
						lable: '创建时间',
						value: 0
					},
					{
						lable: '发放时间',
						value: 1
					},
					{
						lable: '使用时间',
						value: 2
					}
				],
				startTime: null,
				endTime: null,
				tableData: [],
				page: {
					total: 0,
					current: 1,
					size: 5
				},
			}
		},
		created() {
			this.codeShow = this.codeVisible
		},
		watch: {
			codeVisible(n) {
				this.codeShow = n
				if(n) {
					this.timeType = 0
					this.searchKey= ''
					this.status = null
					this.page.current = 1
					this.startTime = null
					this.endTime = null
					this.getSet()
					this.getList()
				}
			},
			couponId(n) {
				this.couponId = n
			},
		},
		methods: {
			validateIntCount(num) {
				return validateInt(num)
			},
			// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						Id: this.couponId,
						KeyWords: this.searchKey,
						State: this.status,
						TimeType: this.timeType,
						StartTime: this.startTime,
						EndTime: this.endTime,
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
					}
					let result = await activitycouponcodeindex(data);

					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results || [];
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			// 获取列表数据
			async getSet() {
				try {
					let data = {
						Id: this.couponId
					}
					let result = await activitycouponcodeinfo(data);

					this.switchValue = result.Result.IsOpenCouponCode;
					this.backUrl = result.Result.CouponCodeBackUrl;
				} catch (error) {
					console.log(error);
				}
			},

			openNewWindow(orderNo) {
				let url = '/order/orderDetailpage?Id=' + orderNo
				this.$common.openNewWindow(url)
			},
			async handelEditCode() {
				try {
					let data = {
						Id: this.couponId,
						IsOpenCouponCode: this.switchValue,
						CouponCodeBackUrl: this.backUrl
					}
					let result = await activitycouponcodeedit(data);
					if (result.IsSuccess) {
						this.$message({
							type: 'success',
							message: '操作成功',
							showClose: true
						})
					}
				} catch (error) {
					console.log(error);
				} finally {}
			},

			handleExport() {
				this.loading = true
				try {
					let url = this.exportUrl + '/pc/activitycoupon/activitycouponcodeexport?' +
						'Id=' + this.couponId +
						'&KeyWords=' + this.searchKey +
						'&State=' + (this.State == null ? '' : this.State) +
						'&TimeType=' + (this.timeType == null ? '' : this.timeType) +
						'&StartTime=' + (this.startTime == null ? '' : this.startTime) +
						'&EndTime=' + (this.endTime == null ? '' : this.endTime)
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false
				}
			},
			async savePopover() {
				if (!this.count && this.count != 0) {
					this.count = null
					this.$message({
						type: 'error',
						message: '请输入券码生成数量',
						showClose: true
					})
					return
				}
				if (Number(this.count) > 10000 || Number(this.count) < 1 || !this.count) {
					this.count = null
					this.$message({
						type: 'error',
						message: '生成数量必须大于等于1且小于等于10000',
						showClose: true
					})
					return
				}
				try {
					this.saveLoading = true
					let data = {
						Id: this.couponId,
						GenerateCount: Number(this.count)
					}
					let result = await activitycouponcodegenerate(data);
					if (result.IsSuccess) {
						this.$message({
							type: 'success',
							message: '券码生成成功',
							showClose: true
						})
						this.popoverVisible = false
						this.count = null
						this.saveLoading = false
						this.handleFilter()
					}
				} catch (error) {
					console.log(error);
					this.saveLoading = false
				}
			},
			canclePopover() {
				this.popoverVisible = false
				this.count = null
			},
			closed() {
				this.$emit('closedCode')
			},

			handleAvatarSuccess(res, file) {
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					alert('请上传小于2M的图片');
					return false;
				}
				this.backUrl = res[0];
				this.handelEditCode()
			},

			handleFilter() {
				this.page.current = 1;
				this.getList()
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
		}
	}
</script>

<style lang='less' scoped>
	.top {
		display: flex;
		justify-content: space-between;

		.config {
			width: 50%;
			vertical-align: top;
			display: flex;

			label {
				margin-right: 10px;
			}

			.remark {
				width: 200px;
			}

			img {
				width: 64px;
				height: 64px;
			}

			.avatar-uploader {
				margin-right: 20px;

				::v-deep .el-upload--picture-card {
					width: 66px;
					height: 66px;
					line-height: 66px;
					border-radius: 0;
				}

				::v-deep .upload-avatar {
					width: 64px;
					height: 64px;
					object-fit: contain;
				}
			}
		}
	}

	.filter-container {
		margin-top: 20px;

		.filter-item {
			margin-right: 20px;

			.btn {
				margin-left: 10px;
			}
		}
	}

	.table-container {
		overflow: hidden;
	}
</style>
